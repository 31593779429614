<template>
  <div class="company">
    <div v-if="!loading && !error.error">
      <div class="container">
        <h3 class="fw-light"> {{ company.name }}
          <span class="badge bg-code text-dark fw-light">{{ company._id }}</span>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-3 col-lg-2 border-end">
            <ul class="nav nav-pills flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'companyOverview' }">Overview</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'companyTeam' }">Team</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'companyBilling' }">Billing</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-9 col-lg-10">
            <router-view :initialCompany="company"></router-view>
          </div>
        </div>
      </div>
    </div>

    <Loader v-if="loading" />
    <ErrorCustom v-if="error.error" :text="error.text" />

  </div>
</template>

<script>
import Loader from '../../components/Loader'
import ErrorCustom from '../../components/ErrorCustom'

export default {
  name: 'CompanyDetails',
  components : {
    Loader,
    ErrorCustom
  },
  data() {
    return {
      loading: true,
      error: {
        error: false,
        text: '',
        code: '',
      },
      company: {},
      datas: {}
    }
  },
  methods: {
    loadAsyncData(id) {
        this.loading = true
        this.error.error = false
        this.error.text = ''
        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + id
        this.$http.get(endpoint)
          .then(({ data }) => {
              this.company = data
              this.loading = false
          })
          .catch((error) => {
              this.error.error = true
              this.error.text = error.response.statusText
              this.loading = false
              throw error
          })
    },
  },
  created() {
      this.loadAsyncData(this.$route.params.id)
  },
  updated() {
    if (this.company._id && this.company._id != this.$route.params.id) {
      this.loadAsyncData(this.$route.params.id)
    }
  }
}
</script>
