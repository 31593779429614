<template>
  <div class="company">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h4>Company
          <small class="text-muted">({{ total }})</small>
        </h4>
        <form class="" @submit.prevent="search()">
          <div class="input-group">
            <input type="text" v-model="keyword" class="form-control bg-transparent" placeholder="Search...">
            <button class="btn btn-primary" @click.prevent="search()" type="button">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </form>
      </div>
      <hr />
      <SkeletonTableList v-if="!ready" :columns="skeleton.columns" />
      <b-table v-else
          :data="data"
          :loading="loading"
          :sticky-header="stickyHeader"
          :hoverable="hoverable"
          :scrollable="scrollable"

          pagination-position="top"
          pagination-size="is-small"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          :row-class="(row, index) => typeof row.subscription.contact_id !== 'undefined' && 'is-zoho'"
          >

          <b-table-column field="name" label="Name" sticky v-slot="props" width="500">
            <router-link :to="{ name: 'companyOverview', params: { id: props.row.id }}">{{ props.row.name }}</router-link>
          </b-table-column>

          <b-table-column field="url" label="Url" v-slot="props">
              {{ props.row.url }}
          </b-table-column>

          <b-table-column field="countryCode" label="Country" v-slot="props">
            {{ $isoCountryCodeToFullText(props.row.countryCode) }}
          </b-table-column>

          <b-table-column field="createdDate" label="Date inscription" v-slot="props">
              {{ new Date(props.row.createdDate).toLocaleDateString() }}
          </b-table-column>

          <b-table-column field="subscription.subscriptionType" label="Abonnement" v-slot="props">
              <span class="badge" :class="type(props.row.subscription.subscriptionType)">
                {{ props.row.subscription.subscriptionType }}
              </span>
          </b-table-column>

          <b-table-column field="subscription.simulator" label="Simulator" centered v-slot="props">
            <span>
              <i :class="props.row.subscription.simulator ? 'bi bi-check-circle-fill' : 'bi bi-circle'"></i>
            </span>
          </b-table-column>

          <b-table-column field="zoho" label="Zoho" centered v-slot="props">
            <span>
              <i :class="typeof props.row.subscription.contact_id !== 'undefined' ? 'bi bi-check-circle-fill' : 'bi bi-circle'"></i>
            </span>
          </b-table-column>

          <b-table-column field="customerId" label="Stripe" centered v-slot="props">
            <span>
              <i :class="typeof props.row.customerId !== 'undefined' ? 'bi bi-check-circle-fill' : 'bi bi-circle'"></i>
            </span>
          </b-table-column>

          <b-table-column field="yobs" label="Yobs" centered v-slot="props">
            <span>
              <i :class="checkYobs(props.row.aiProvider) ? 'bi bi-check-circle-fill' : 'bi bi-circle'"></i>
            </span>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>

      </b-table>

    </div>
  </div>
</template>

<script>

import SkeletonTableList from '../../components/skeleton/TableList'

export default {
  name: 'Company',
  components : {
    SkeletonTableList
  },
  data() {
    return {
        data: [],
        skeleton: {
          columns: [
            {field: 'name',label: 'Name',},
            {field: 'url',label: 'Url',},
            {field: 'countryCode',label: 'Country',},
            {field: 'createdDate',label: 'Date inscription',},
            {field: 'subscriptionType',label: 'Abonnement',},
            {field: 'simulator',label: 'Simulator',},
            {field: 'zoho',label: 'Zoho',},
            {field: 'customerId',label: 'Stripe',},
            {field: 'yobs',label: 'Yobs',},
          ]
        },
        total: 0,
        loading: true,
        ready: false,
        stickyHeader: true,
        hoverable: true,
        scrollable: true,
        sortField: 'signupDate',
        sortOrder: '-',
        defaultSortOrder: 'desc',
        page: 1,
        perPage: 50,
        keyword: '',
    }
  },
  methods: {
    search() {
      const route = '/company?search=' + encodeURIComponent(this.keyword)
      if (route != this.$router.history.current.fullPath) {
        this.$router.replace(route)
        this.loadAsyncData()
      }
    },
    loadAsyncData() {
        let params = [
            'page=' + this.page,
            'limit=' + this.perPage,
            'sort=' + this.sortOrder + this.sortField,
        ]

        if (this.keyword != '') {
          params.push('search=' + this.keyword)
        }

        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company?' + params.join('&')

        this.loading = true
        this.$http.get(endpoint)
            .then(({ data }) => {
                this.data = []
                this.total = data.totalDocs
                data.docs.forEach((item) => {
                    this.data.push(item)
                })
                this.loading = false
                this.ready = true
            })
            .catch((error) => {
                this.data = []
                this.total = 0
                this.loading = false
                throw error
            })
    },
    onPageChange(page) {
        this.page = page
        this.loadAsyncData()
    },
    onSort(field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadAsyncData()
    },
    type(value) {
        let arrBgMap = {
          "PAYG" : "bg-dark",
          "MONTHLY" : "bg-primary",
          "MONTHLYWITHOUTCARD" : "bg-secondary text-dark"
        }
        return arrBgMap[value] ?? ""
    },
    checkYobs(value) {
      if (typeof value === 'undefined') return false
      return value.some(x => x.aiProviderType == 'YOBS')
    },
  },
  filters: {
    truncate(value, length) {
        return value.length > length
            ? value.substr(0, length) + '...'
            : value
    }
  },
  mounted() {
      if (this.$route.query.search) {
        this.keyword = this.$route.query.search
      }
      this.loadAsyncData()
  }
}
</script>
