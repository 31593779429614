<template>
  <div class="container">
    <form class="form-auth" @submit.prevent="onSubmit()">
      <div class="text-center mb-4">
        <h4>Register</h4>
      </div>

      <div class="alert alert-danger" role="alert" v-show="showerr">
        {{ errmsg }}
      </div>

      <div class="form-label-group">
        <input v-model="firstname" type="text" id="inputFirstname" class="form-control" placeholder="Firstname" autofocus="">
        <label for="inputFirstname">Firstname</label>
      </div>

      <div class="form-label-group">
        <input v-model="lastname" type="text" id="inputLastname" class="form-control" placeholder="Lastname" autofocus="">
        <label for="inputLastname">Lastname</label>
      </div>

      <div class="form-label-group">
        <input v-model="email" type="text" id="inputEmail" class="form-control" placeholder="Email address" autofocus="">
        <label for="inputEmail">Email address</label>
      </div>

      <div class="form-label-group">
        <input v-model="password" type="password" id="inputPassword" class="form-control" placeholder="Password">
        <label for="inputPassword">Password</label>
      </div>

      <button class="btn btn-primary btn-block d-block" type="submit" :disabled="loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="loading"></span>
        Register
      </button>
      <div class="d-flex justify-content-between mt-2">
        <router-link :to="{ name: 'Login' }">Sign in</router-link>
        <router-link :to="{ name: 'Resend' }">Confirm</router-link>
      </div>
    </form>
  </div>
</template>

<script>

  var AmazonCognitoIdentity = require('amazon-cognito-identity-js')
  var userPool = []
  var attributeList = []
  var dataEmail = { Name: 'email', Value: '' }
  var dataFirstname = { Name: 'given_name', Value: '' }
  var dataLastname = { Name: 'family_name', Value: '' }

  export default {
    data() {
      return {
        callback: false,
        showerr: false,
        errcode: '',
        errmsg: '',
        username: '',
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        loader: false,
        loading: false,
      }
    },
    methods: {
      onSubmit() {
        this.loader = 'loading'
        const l = this.loader
        this[l] = !this[l]

        dataEmail.Value = this.email
        dataFirstname.Value = this.firstname
        dataLastname.Value = this.lastname
        var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail)
        var attributeFirstname = new AmazonCognitoIdentity.CognitoUserAttribute(dataFirstname)
        var attributeLastname = new AmazonCognitoIdentity.CognitoUserAttribute(dataLastname)
        attributeList.push(attributeEmail)
        attributeList.push(attributeFirstname)
        attributeList.push(attributeLastname)

        userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId : process.env.VUE_APP_AWS_COGNITO_USER_POOL,
          ClientId: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID
        })
        this.callback = false
        this.errcode = ''
        this.username = ''

        userPool.signUp(this.email, this.password, attributeList, null, (err, result) => {
          if (!this.callback) {
            this.callback = true
            if (err) {
              this.errcode = JSON.stringify(err.code)
            } else {
              this.message = JSON.stringify(result.message)
              this.username = result.user.getUsername()
              this.$store.commit('setUsername', this.username)
              this.$router.push('/confirm')
            }
            this[l] = false
            this.loader = null
          }
        })
      },
      getMessage: function () {
        return this.message
      }
    },
    watch: {
      errcode () {
        console.log('watched error code: ' + this.errcode)
        if (this.errcode !== '') {
          if (this.errcode === '"UsernameExistsException"') {
            this.errmsg = 'An account with the given email already exists!'
          } else {
            this.errmsg = 'An error has occured!'
          }
          this.showerr = true
        }
      }
    }
  }
</script>
