<template>
<aside class="sidebar">

    <div class="side-inner">

      <div class="nav-menu">
        <ul>
          <li><router-link :to="{ name: 'Company' }">Company</router-link></li>
          <li><router-link :to="{ name: 'Applicant' }">Applicant</router-link></li>
          <li><router-link :to="{ name: 'YobsApplicant' }">Yobs Applicant</router-link></li>
          <li><router-link :to="{ name: 'YobsCompany' }">Yobs Company</router-link></li>
          <li><router-link :to="{ name: 'Provider' }">Provider</router-link></li>
        </ul>
      </div>
    </div>

  </aside>
</template>

<script>
  export default {
    name: 'TheSidebar',
  }
</script>
