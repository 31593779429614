<template>
  <div class="yobs">
    <div class="container">
      <h1 class="display-6">Provider
      </h1>
      <hr />

      <b-table
          :data="data"
          :loading="loading"
          :sticky-header="stickyHeader"
          :hoverable="hoverable"
          :scrollable="scrollable"

          pagination-position="top"
          pagination-size="is-small"
          paginated

          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"

          ref="multiSortTable"
          :backend-sorting="backendSortingEnabled"
          @sort="sortPressed"
          @sorting-priority-removed="sortingPriorityRemoved"

          :sort-multiple="multiColumnSortingEnabled"
          :sort-multiple-data="sortingPriority"
          :sort-multiple-key="customKey"
          sort-icon-size="is-small"

          :selected.sync="selected"
          >

          <b-table-column field="_id" label="Id" v-slot="props">
              {{ props.row._id }}
          </b-table-column>

          <b-table-column field="name" label="Name" sortable searchable v-slot="props">
            <router-link :to="{ name: 'ProviderDetails', params: { id: props.row._id }}">{{ props.row.name }}</router-link>
          </b-table-column>

      </b-table>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Yobs',
  data() {
    return {
      scrollable: true,
      customKey: null,
      stickyHeader: false,
      hoverable: true,
      backendSortingEnabled: false,
      multiColumnSortingEnabled: false,
      data: [],
      total: 0,
      sortingPriority: [],
      loading: false,
      selected: null,
      waiting: false,
    }
  },
  methods: {
    loadAsyncData(sortingPriority = []) {
      var elm = this
      this.loading = true
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/provider'
      this.$http.get(endpoint)
        .then(({ data }) => {
            this.data = data
            this.total = data.length
            this.loading = false
        })
        .catch((error) => {
            elm.data = []
            elm.total = 0
            elm.loading = false
            throw error
        })
    },
    resetPriority(){
      this.$refs.multiSortTable.resetMultiSorting()

      // reset local backend sorting
      if(this.backendSortingEnabled) {
        this.sortingPriority = []
        this.loadAsyncData()
      }
    },
    // Backend sorting
    sortingPriorityRemoved(field){
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field)
      this.loadAsyncData(this.sortingPriority)
    },
    sortPressed(field, order, event) {
      if(this.backendSortingEnabled) {
        if(this.multiColumnSortingEnabled){
          if((this.customKey && event[this.customKey]) || !this.customKey) {
            let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
            if(existingPriority) {
              existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
            } else {
              // request sorted data from backend
              this.sortingPriority.push({field, order})
            }
            this.loadAsyncData(this.sortingPriority)
          } else {
            // request regular sorted data from backend
            this.sortingPriority = [] // [{field, order}]
            this.loadAsyncData([{field, order}])
          }
        }
      }
    },
  },
  mounted() {
      this.loadAsyncData()
  },
  created() {

  }
}
</script>
