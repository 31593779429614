<template>
  <div class="yobs">
    <div class="container">
      <h4>Yobs Ai Report</h4>
      <hr />

      <b-table
          :data="data"
          :loading="loading"
          :sticky-header="stickyHeader"
          :hoverable="hoverable"
          :scrollable="scrollable"

          pagination-position="top"
          pagination-size="is-small"
          paginated

          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"

          ref="multiSortTable"
          :backend-sorting="backendSortingEnabled"
          @sort="sortPressed"
          @sorting-priority-removed="sortingPriorityRemoved"

          :sort-multiple="multiColumnSortingEnabled"
          :sort-multiple-data="sortingPriority"
          :sort-multiple-key="customKey"
          sort-icon-size="is-small"

          :selected.sync="selected"
          >

          <b-table-column field="yobs.status" label="Status" sortable searchable v-slot="props">
            <span class="badge" :class="status(props.row.yobs.status)" @click.prevent="test">
              {{ props.row.yobs.status }}
            </span>
          </b-table-column>

          <b-table-column field="_id" label="id" sortable searchable v-slot="props">
            {{ props.row._id }}
          </b-table-column>

          <b-table-column field="email" label="Email" sortable searchable v-slot="props">
            {{ props.row.email }}
          </b-table-column>

          <b-table-column field="company" label="companyId" sortable searchable v-slot="props">
            {{ props.row.company }}
          </b-table-column>

          <b-table-column field="personId" label="personId" searchable v-slot="props">
            {{ props.row.yobs.personId || "" }}
          </b-table-column>

          <b-table-column field="" label="Action" searchable v-slot="props">
            <span class="badge bg-primary" v-if="props.row.yobs.status == 'failed'" @click.prevent="postPeople(props.row._id)">
                retry
            </span>
          </b-table-column>

      </b-table>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Yobs',
  data() {
    return {
      scrollable: true,
      customKey: null,
      stickyHeader: false,
      hoverable: true,
      backendSortingEnabled: false,
      multiColumnSortingEnabled: false,
      data: [],
      total: 0,
      sortingPriority: [],
      loading: false,
      selected: null,
      waiting: false,
    }
  },
  methods: {
    postPeople(id) {
      var elm = this
      console.log(id)

      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/yobs/people/'
      elm.$http.post(endpoint, { applicantid: id }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        console.log(data)
      })
      .catch((error) => {

      })

    },
    test() {
      var elm = this
      if (elm.selected) {
        if (!elm.waiting) {
          if (elm.selected.yobs.status == "processing") {
            elm.waiting = true
            elm.selected.yobs.status = "loading"
            const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/yobs/people/' + elm.selected.yobs.personId
            elm.$http.put(endpoint)
              .then(({ data }) => {
                if (data.ok == 1 && data.nModified == 1) {
                  elm.selected.yobs.status = "saved"
                } else {
                  elm.selected.yobs.status = "error"
                }
                elm.waiting = false
              })
              .catch((error) => {
                console.log(error)
                elm.waiting = false
              })
          }
        }
      }
    },
    loadAsyncData(sortingPriority = []) {
      var elm = this
      this.loading = true
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/yobs/applicant'
      this.$http.get(endpoint)
        .then(({ data }) => {
            this.data = data
            this.total = 10
            this.loading = false
        })
        .catch((error) => {
            elm.data = []
            elm.total = 0
            elm.loading = false
            throw error
        })
    },
    status(value) {
      var status = ''
      switch (value) {
        case 'merge_failed':
          status = 'bg-warning text-dark'
          break;
        case 'processing':
          status = 'bg-info text-dark'
          break;
        case 'failed':
          status = 'bg-danger text-dark'
          break;
        case 'saved':
          status = 'bg-success text-dark'
          break;
        case 'manual':
          status = 'bg-primary'
          break;
        case 'loading':
          status = 'bg-dark text-light'
        default:
      }
      return status
    },
    resetPriority(){
      this.$refs.multiSortTable.resetMultiSorting()

      // reset local backend sorting
      if(this.backendSortingEnabled) {
        this.sortingPriority = []
        this.loadAsyncData()
      }
    },

    // Backend sorting
    sortingPriorityRemoved(field){
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field)
      this.loadAsyncData(this.sortingPriority)
    },

    sortPressed(field, order, event) {
      if(this.backendSortingEnabled) {
        if(this.multiColumnSortingEnabled){
          if((this.customKey && event[this.customKey]) || !this.customKey) {
            let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
            if(existingPriority) {
              existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
            } else {
              // request sorted data from backend
              this.sortingPriority.push({field, order})
            }
            this.loadAsyncData(this.sortingPriority)
          } else {
            // request regular sorted data from backend
            this.sortingPriority = [] // [{field, order}]
            this.loadAsyncData([{field, order}])
          }
        }
      }
    },
  },
  mounted() {
      this.loadAsyncData()
  },
  created() {

  }
}
</script>
