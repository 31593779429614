<template>
  <div class="errorCustom container">
    <div class="row">
      <div class="alert alert-danger col-lg-6 offset-lg-3 mt-4 text-center" role="alert">
        <h4 class="alert-heading"><i class="bi bi-info-circle"></i></h4>
        <p>{{ text || "404 Not Found" }}</p>
        <hr>
        <p class="mb-0">
          You can go back to the <a href="#" @click.prevent="goBack" class="alert-link">previous page</a>, return to our
          <router-link
            to="/"
            v-slot="{ href, route, navigate }"
            custom>
            <a :href="href" @click="navigate" class="alert-link">homepage</a>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ErrorCustom',
    props: ['text'],
    data: function() {
      return {

      }
    },
    methods: {
      goBack() {
        this.$router.back()
      }
    }
  }
</script>
