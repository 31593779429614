<template>
  <div class="yobs">
    <div class="container">
      <h4 class="d-flex justify-content-between align-items-center">
        <span>Yobs Account</span>
      </h4>
      <hr />

      <b-table
          :data="data"
          :loading="loading"
          :sticky-header="stickyHeader"
          :hoverable="hoverable"
          :scrollable="scrollable"

          pagination-position="top"
          pagination-size="is-small"
          paginated

          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"

          ref="multiSortTable"
          :backend-sorting="backendSortingEnabled"
          @sort="sortPressed"
          @sorting-priority-removed="sortingPriorityRemoved"

          :sort-multiple="multiColumnSortingEnabled"
          :sort-multiple-data="sortingPriority"
          :sort-multiple-key="customKey"
          sort-icon-size="is-small"

          detailed
          detail-key="_id"
          @cellclick="cellclick"
          >

          <b-table-column field="name" label="Name" sortable searchable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="yobs.status" label="Status" sortable searchable v-slot="props">
            <button v-if="(props.row.yobs.status == 'expired')" :disabled="tokenLoad" class="btn btn-outline-primary badge bg-primary text-white border-0" @click.prevent="showModal(props.row.yobs.token)">
              renew expired token
            </button>
            <span v-else class="badge" :class="status(props.row.yobs.status)">
              {{ props.row.yobs.status }}
            </span>
          </b-table-column>

          <b-table-column field="yobs.expireAt" label="expireAt" sortable searchable v-slot="props">
            <small>{{ DateTime.fromMillis(props.row.yobs.expireAt * 1000).toUTC().toISO() }}</small>
          </b-table-column>

          <b-table-column field="name" label="_id" sortable searchable v-slot="props">
            {{ props.row._id }}
          </b-table-column>

          <b-table-column field="yobs.beginDate" label="Date" sortable searchable v-slot="props">
            {{ new Date(props.row.yobs.beginDate).toLocaleDateString() }}
          </b-table-column>

          <b-table-column field="yobs.accountId" label="AccountId" sortable searchable v-slot="props">
            {{ props.row.yobs.accountId }}
          </b-table-column>

          <template #detail="props">
              {{ props.row.yobs.token }}
          </template>

      </b-table>

    </div>
    <v-dialog :clickToClose="false"/>
  </div>
</template>

<script>

export default {
  name: 'Yobs',
  data() {
    return {
      scrollable: true,
      customKey: null,
      stickyHeader: false,
      hoverable: true,
      backendSortingEnabled: false,
      multiColumnSortingEnabled: false,
      data: [],
      total: 0,
      sortingPriority: [],
      loading: false,
      provider: {},
      tokenLoad: false,
      row: null,
    }
  },
  methods: {
    loadAsyncData(sortingPriority = []) {
      var elm = this
      this.loading = true
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/yobs/company'
      this.$http.get(endpoint)
        .then(({ data }) => {
            this.data = data
            this.total = 10
            this.loading = false
        })
        .catch((error) => {
            elm.data = []
            elm.total = 0
            elm.loading = false
            throw error
        })
    },
    status(value) {
      var status = ''
      switch (value) {
        case 'invalid':
          status = 'bg-danger text-dark'
          break;
        case 'expired':
          status = 'bg-warning text-dark'
          break;
        case 'valid':
          status = 'bg-success text-dark'
          break;
      }
      return status
    },
    resetPriority(){
      this.$refs.multiSortTable.resetMultiSorting()

      // reset local backend sorting
      if(this.backendSortingEnabled) {
        this.sortingPriority = []
        this.loadAsyncData()
      }
    },
    sortingPriorityRemoved(field){
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field)
      this.loadAsyncData(this.sortingPriority)
    },
    sortPressed(field, order, event) {
      if(this.backendSortingEnabled) {
        if(this.multiColumnSortingEnabled){
          if((this.customKey && event[this.customKey]) || !this.customKey) {
            let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
            if(existingPriority) {
              existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
            } else {
              // request sorted data from backend
              this.sortingPriority.push({field, order})
            }
            this.loadAsyncData(this.sortingPriority)
          } else {
            // request regular sorted data from backend
            this.sortingPriority = [] // [{field, order}]
            this.loadAsyncData([{field, order}])
          }
        }
      }
    },
    cellclick(row, column, rowIndex, columnIndex) {
      //row.yobs.status = "invalid"
      if (columnIndex == 1) {
        this.row = row
      } else {
        this.row = null
      }
    },
    showModal(token) {
      var elm = this
      this.$modal.show('dialog', {
        title: 'Renew Yobs Token',
        text: 'renew ',
        buttons: [
          {
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Renew',
            handler: () => {
              elm.tokenLoad = true

              const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/provider/' + elm.row._id + '/renew'
              this.$http.post(endpoint, { token: token }, {
                 headers: {
                      'Content-Type': 'application/json'
                 }
              })
              .then(({ data }) => {
                elm.row.yobs.status = "valid"
                elm.row.yobs.token = data.token
                this.$toast.success(data.message, {})
              })
              .catch((error) => {
                this.$toast.error(error.response.data.message, {})
              })
              .then(() => {
                elm.tokenLoad = false
                this.$modal.hide('dialog')
              })
              //elm.row.yobs.status = "invalid"
            }
          }
        ]
      })
    }
  },
  mounted() {
      this.loadAsyncData()
  }
}
</script>
