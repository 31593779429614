<template>
  <div class="d-flex justify-content-center mt-5">
    <div class="spinner-grow " role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  }
</script>
