<template>
  <div class="container">
    <form class="form-auth" @submit.prevent="onSubmit()">
      <div class="text-center mb-4">
        <img class="mb-4" src="@/assets/interviewapp-logo-black_b17el8.png" alt="InterviewApp" height="25">
      </div>

      <div class="alert alert-danger" role="alert" v-show="showerr">
        {{ errmsg }}
        <a v-if="resend" href="#" class="alert-link">re-send confirmation</a>
      </div>

      <div class="form-label-group">
        <input v-model="email" type="email" id="inputEmail" class="form-control" placeholder="Email address" required="" autofocus="">
        <label for="inputEmail">Email address</label>
      </div>

      <div class="form-label-group">
        <input v-model="password" type="password" id="inputPassword" class="form-control" placeholder="Password" required="">
        <label for="inputPassword">Password</label>
      </div>

      <button class="btn btn-primary btn-block d-block" type="submit" :disabled="loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="loading"></span>
        Sign In
      </button>
    </form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        callback: false,
        showerr: false,
        resend: false,
        errmsg: '',
        username: '',
        valid: false,
        email: '',
        password: '',
        loader: false,
        loading: false
      }
    },
    computed: {
      errcode: function() {
        return this.$store.state.auth.errcode
      }
    },
    methods: {
      onSubmit() {
        this.loader = 'loading'
        const l = this.loader
        this[l] = !this[l]
        var authData = {
          Username: this.email,
          Password: this.password
        }
        this.$store.dispatch('signIn', authData)
      },
      getMessage: function() {
        return this.message
      }
    },
    watch: {
      errcode () {
        console.log(this.errcode)
        this.loader = 'loading'
        const l = this.loader
        this[l] = !this[l]
        if (this.errcode && this.errcode !== '') {
          if (this.errcode === '"NotAuthorizedException"') {
            this.errmsg = 'Incorrect username or password'
          } else if (this.errcode === '"UserNotFoundException"') {
            this.errmsg = 'User not found'
          } else if (this.errcode === '"UserNotConfirmedException"') {
            this.$store.commit('setUsername', this.email)
            this.resend = true
            this.errmsg = 'User registration not confirmed'
          } else if (this.errcode === '"LimitExceededException"') {
            this.errmsg = 'Attempt limit exceeded, please try after some time'
          } else {
            this.errmsg = 'An error has occured!'
          }
          this.showerr = true
        } else {
          this.showerr = false
        }
        this[l] = false
        this.loader = null
      }
    }
  }
</script>
