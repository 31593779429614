<template>
  <div class="wrap-nav">
    <nav class="navbar navbar-light bg-secondary p-3 fixed-top">
      <div class="col-6 d-flex">
          <a class="navbar-brand" href="#">
              <img src="@/assets/interviewapp-logo-black_b17el8.png" alt="InterviewApp" height="25" />
          </a>
      </div>
      <div class="col-6 d-flex justify-content-end">
          <div v-if="isAuthenticated" class="me-4">
            <i class="bi bi-search" style="font-size:24px;" @click.prevent="openSearch()"></i>
          </div>
          <div v-if="isAuthenticated" class="dropdown">
            <button class="me-4 btn btn-link link-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
              {{user.firstname}} {{user.lastname}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><a class="dropdown-item" href="#" @click.prevent="logout">Sign out</a></li>
            </ul>
          </div>
          <div v-else>
            <router-link to="/login" v-slot="{ href, route, navigate }" custom>
              <a class="me-4 btn btn-link link-dark" type="button" :href="href" @click="navigate">
                Sign in
              </a>
            </router-link>
          </div>
      </div>
    </nav>
    <Search :isSearchOpen.sync="isSearchOpen" />
  </div>
</template>

<script>

  //var AmazonCognitoIdentity = require('amazon-cognito-identity-js')
  import Search from './Search'
  export default {
    name: 'TheNavbar',
    components: {
      Search,
    },
    data() {
      return {
        isSearchOpen: false,
        user: {
          firstname: '',
          lastname: '',
          email: ''
        }
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('signOut')
      },
      openSearch() {
        this.isSearchOpen = !this.isSearchOpen
        if(this.isSearchOpen){
          document.body.classList.add("search-open")
        }else{
          document.body.classList.remove("search-open")
        }
      },
      getAttributes: function () {
        if (this.$store.getters.getStateAttributes.length < 1) {
          this.$store.state.auth.cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log('get attribute error: ' + err)
            } else {
              this.$store.commit('setAttributes', result)
              this.mapAttributes(result)
            }
          })
        } else {
          let result = this.$store.getters.getStateAttributes
          this.mapAttributes(result)
        }
      },
      mapAttributes: function (result) {
        for (let attribute of result) {
          if (attribute.Name === 'given_name') {
            this.user.firstname = attribute.Value
          } else if (attribute.Name === 'family_name') {
            this.user.lastname = attribute.Value
          }
        }
      }
    },
    computed: {
      isAuthenticated: function () {
        return this.$store.state.auth.authenticated
      }
    },
    beforeMount: function () {
      if (this.$store.state.auth.authenticated === true) {
        this.getAttributes()
      }
    },
    updated() {
      if (this.$store.state.auth.authenticated === true) {
        this.getAttributes()
      }
    }
  }
</script>
