import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store'
import Home from '../views/Home.vue'

import Company from '../views/Company/List.vue'
import CompanyDetails from '../views/Company/Details.vue'
import CompanyDetailsInformations from '../views/Company/DetailsInformations.vue'
import CompanyDetailsOverview from '../views/Company/DetailsOverview.vue'
import CompanyDetailsTeam from '../views/Company/DetailsTeam.vue'
import CompanyDetailsBilling from '../views/Company/DetailsBilling.vue'

import YobsApplicant from '../views/Yobs/ListApplicant.vue'
import YobsCompany from '../views/Yobs/ListCompany.vue'

import Provider from '../views/Provider/List.vue'
import ProviderDetails from '../views/Provider/Details.vue'

import Applicant from '../views/Applicant/List.vue'
import ApplicantDetails from '../views/Applicant/Details.vue'

import Login from '../components/auth/Login.vue'
import Register from '../components/auth/Register.vue'
import Resend from '../components/auth/Resend.vue'
import Confirm from '../components/auth/Confirm.vue'
import Registered from '../components/auth/Registered.vue'

import ErrorCustom from '../components/ErrorCustom.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/resend', name: 'Resend', component: Resend },
  { path: '/confirm', name: 'Confirm', component: Confirm },
  { path: '/registered', name: 'Registered', component: Registered },
  {
    path: '/company/:id',
    component: CompanyDetails,
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', name: 'companyOverview', component: CompanyDetailsOverview, },
      { path: 'informations', component: CompanyDetailsInformations, },
      { path: 'team', name: 'companyTeam', component: CompanyDetailsTeam, },
      { path: 'billing', name: 'companyBilling', component: CompanyDetailsBilling, },
    ]
  },
  { path: '/company', name: 'Company', component: Company, meta: { requiresAuth: true } },
  { path: '/applicant', name: 'Applicant', component: Applicant, meta: { requiresAuth: true } },
  { path: '/applicant/:id', name: 'ApplicantDetails', component: ApplicantDetails, meta: { requiresAuth: true } },
  { path: '/yobs/applicant', name: 'YobsApplicant', component: YobsApplicant, meta: { requiresAuth: true } },
  { path: '/yobs/company', name: 'YobsCompany', component: YobsCompany, meta: { requiresAuth: true } },
  { path: '/provider/:id', name: 'ProviderDetails', component: ProviderDetails, meta: { requiresAuth: true } },
  { path: '/provider', name: 'Provider', component: Provider, meta: { requiresAuth: true } },

  { path: '/:pathMatch(.*)*', component: ErrorCustom },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach( (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.authenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
