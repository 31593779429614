<template>
  <div class="applicant">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h4>Applicant
          <small class="text-muted">({{ total }})</small>
        </h4>
        <form class="" @submit.prevent="search()">
          <div class="input-group">
            <input type="text" v-model="keyword" class="form-control bg-transparent" placeholder="Search...">
            <button class="btn btn-primary" @click.prevent="search()" type="button">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </form>
      </div>
      <hr />
      <SkeletonTableList v-if="!ready" :columns="skeleton.columns" />
      <b-table v-else
          :data="data"
          :loading="loading"
          :sticky-header="stickyHeader"
          :hoverable="hoverable"
          :scrollable="scrollable"

          pagination-position="top"
          pagination-size="is-small"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"

          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          >

          <b-table-column field="email" label="Email" sticky v-slot="props">
            <router-link :to="{ name: 'ApplicantDetails', params: { id: props.row._id }}">{{ props.row.email }}</router-link>
          </b-table-column>

          <b-table-column field="status" label="Status" v-slot="props">
            <span class="badge text-dark" :class="applicantStatus[status(props.row)].class">
              {{ applicantStatus[status(props.row)].text }}
            </span>
          </b-table-column>

          <b-table-column field="firstname" label="Firstname" v-slot="props">
            {{ props.row.firstname }}
          </b-table-column>

          <b-table-column field="lastname" label="Lastname" v-slot="props">
            {{ props.row.lastname }}
          </b-table-column>

          <b-table-column field="company" label="Company" v-slot="props">
              {{ getCompanyName(props.row.company) }}
          </b-table-column>

          <b-table-column field="interview" label="Interview" v-slot="props">
              {{ getInterviewName(props.row.interview) }}
          </b-table-column>

          <b-table-column field="dateBegin" label="Begin" v-slot="props">
            {{ DateTime.fromISO(props.row.dateBegin, {zone: 'utc'}).toFormat('yyyy-MM-dd HH:mm:ss') }}
          </b-table-column>

          <b-table-column field="dateEnd" label="End" v-slot="props">
            {{ DateTime.fromISO(props.row.dateEnd, {zone: 'utc'}).toFormat('yyyy-MM-dd') }}
          </b-table-column>

          <b-table-column field="dateAnswer" label="Answer" v-slot="props">
            {{ dateAnswer(props.row.dateAnswer) }}
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>
  </div>
</template>

<script>

import SkeletonTableList from '../../components/skeleton/TableList'

export default {
  name: 'Applicant',
  components : {
    SkeletonTableList
  },
  data() {
    return {
        data: [],
        skeleton: {
          columns: [
            {field: 'email',label: 'Email',},
            {field: 'status',label: 'Status',},
            {field: 'firstname',label: 'Firstname',},
            {field: 'lastname',label: 'Lastname',},
            {field: 'company',label: 'Company',},
            {field: 'interview',label: 'Interview',},
            {field: 'dateBegin',label: 'Begin',},
            {field: 'dateEnf',label: 'End',},
            {field: 'dateAnswer',label: 'Answer',},
          ]
        },
        total: 0,
        loading: false,
        ready: false,
        stickyHeader: true,
        hoverable: true,
        scrollable: true,
        sortField: 'dateBegin',
        sortOrder: '-',
        defaultSortOrder: 'desc',
        page: 1,
        perPage: 50,
        applicantStatus: [
          { "class": "bg-danger", "text": "Email not view" },
          { "class": "bg-warning", "text": "Email view" },
          { "class": "bg-info", "text": "inprogress" },
          { "class": "bg-success", "text": "completed" },
          { "class": "bg-light", "text": "expired" },
        ],
        keyword: '',
    }
  },
  methods: {
    search() {
      const route = '/applicant?search=' + encodeURIComponent(this.keyword)
      if (route != this.$router.history.current.fullPath) {
        this.$router.replace(route)
        this.loadAsyncData()
      }
    },
    loadAsyncData() {
        let params = [
            'page=' + this.page,
            'limit=' + this.perPage,
            'sort=' +this.sortOrder + this.sortField,
        ]

        if (this.keyword != '') {
          params.push('search=' + this.keyword)
        }

        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/applicant?' + params.join('&')

        this.loading = true
        this.$http.get(endpoint)
            .then(({ data }) => {
                this.data = []
                this.total = data.totalDocs
                data.docs.forEach((item) => {
                    this.data.push(item)
                })
                this.loading = false
                this.ready = true
            })
            .catch((error) => {
                this.data = []
                this.total = 0
                this.loading = false
                throw error
            })
    },
    onPageChange(page) {
        this.page = page
        this.loadAsyncData()
    },
    onSort(field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadAsyncData()
    },
    status(applicant) {
      if (!applicant.dateAnswer && this.DateTime.local() > this.DateTime.fromISO(applicant.dateEnd)) {
        return 4 // expired
      } else {
        if (applicant.questions.length != applicant.responses.length && !applicant.emailView && !applicant.linkClicked) {
          return 0 // email not view
        } else {
          if (applicant.questions.length != applicant.responses.length && !applicant.linkClicked) {
            return 1 // email view
          } else {
            if (applicant.questions.length != applicant.responses.length) {
              return 2 // in progress
            } else {
              return 3 // completed
            }
          }
        }
      }
    },
    getCompanyName(value) {
      if (value) {
        return ('name' in value) ? value.name : "Deleted company"
      } else {
        return "Deleted company"
      }
    },
    getInterviewName(value) {
      if (value) {
        return ('name' in value) ? value.name : "Interview deleted"
      } else {
        return "Interview deleted"
      }
    },
    dateAnswer(value) {
      return value ? this.DateTime.fromISO(value, {zone: 'utc'}).toFormat('yyyy-MM-dd HH:mm:ss') : ""
    }
  },
  filters: {
    truncate(value, length) {
        return value.length > length
            ? value.substr(0, length) + '...'
            : value
    }
  },
  mounted() {
      if (this.$route.query.search) {
        this.keyword = this.$route.query.search
      }
      this.loadAsyncData()
  }
}
</script>
