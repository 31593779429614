<template>
  <b-table :data="fake" :hoverable="false" :striped="false" class="skeleton-table">
    <b-table-column v-for="column in columns" :key="column.field" :field="column.field" :label="column.label">
      <b-skeleton></b-skeleton>
    </b-table-column>
  </b-table>
</template>

<script>
  export default {
    name: 'SkeletonTableList',
    props: ['columns'],
    data() {
      return {
        fake : []
      }
    },
    methods: {
      skeleton(value) {
        return value + " skeleton"
      },
    },
    mounted() {

      for (var i = 0; i < 5; i++) {
        let d = {}
        for (var col in this.columns) {
          d[this.columns[col].field] = ''
        }
        this.fake.push(d)
      }
    }
  }
</script>
