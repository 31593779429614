<template>
  <div class="container">
    <form class="form-auth" @submit.prevent="onSubmit()">
      <div class="text-center mb-4">
        <h4>A confirmation code was sent to your email</h4>
      </div>

      <div class="alert alert-danger" role="alert" v-show="showerr">
        {{ errmsg }}
      </div>

      <div class="form-label-group">
        <input v-model="code" type="text" id="inputCode" class="form-control" placeholder="Confirmation code" autofocus="">
        <label for="inputCode">Confirmation code</label>
      </div>

      <button class="btn btn-primary btn-block d-block" type="submit" :disabled="loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="loading"></span>
        Confirm
      </button>
    </form>
  </div>
</template>

<script>

  var AmazonCognitoIdentity = require('amazon-cognito-identity-js')
  var userPool = []


  export default {
    data: () => ({
      callback: false,
      confirmed: false,
      showerr: false,
      errcode: '',
      errmsg: '',
      code: '',
      valid: false,
      loader: false,
      loading: false,
    }),
    methods: {
      onSubmit() {
        this.loader = 'loading'
        const l = this.loader
        this[l] = !this[l]
        this.errcode = ''
        this.errmsg = ''
        this.showerr = false
        this.callback = false
        userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId : process.env.VUE_APP_AWS_COGNITO_USER_POOL,
          ClientId: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID
        })
        var userData = {
          Username: this.$store.state.auth.username,
          Pool: userPool
        }
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
        cognitoUser.confirmRegistration(this.code, true, (err, result) => {
          if (!this.callback) {
            this.callback = true
            if (err) {
              this.errcode = JSON.stringify(err.code)
            } else {
              this.confirmed = true
            }
            this[l] = false
            this.loader = null
          }
        })
      }
    },
    watch: {
      confirmed () {
        if (this.confirmed === true) {
          this.$router.push('/registered')
        }
      },
      errcode () {
        console.log('watched error code: ' + this.errcode)
        if (this.errcode !== '') {
          if (this.errcode === '"CodeMismatchException"') {
            this.errmsg = 'Invalid verification code provided'
          } else if (this.errcode === '"NotAuthorizedException"') {
            this.errmsg = 'The user has already been confirmed'
          } else if (this.errcode === '"UserNotFoundException"') {
            this.errmsg = 'Username id not found!'
          } else if (this.errcode === '"LimitExceededException"') {
            this.errmsg = 'Attempt limit exceeded, please try after some time'
          } else {
            this.errmsg = 'An error has occured!'
          }
          this.showerr = true
        }
      }
    },
    created() {
      this.email = this.$store.state.auth.username || ''
      console.log(this.email)
    }
  }
</script>
