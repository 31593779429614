<template>
  <div class="home">
    <div class="container mt-5">
    
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  computed: {
    isAuthenticated: function () {
      return this.$store.state.auth.authenticated
    },
    email: function () {
      return this.$store.state.auth.username
    }
  },
}
</script>
