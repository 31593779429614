<template>
  <div class="card mb-2 shadow">
    <div class="card-header fw-bold">
      <b-skeleton width="100%"></b-skeleton>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <b-skeleton :count="6"></b-skeleton>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'SkeletonCard',
  }
</script>
