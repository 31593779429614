<template>
  <div class="applicant">
    <div v-if="!loading && !error.error">
      <div class="container">
        <h3 class="fw-light"> Applicant
          <span class="badge bg-code text-dark fw-light">{{ applicant._id }}</span>
        </h3>
        <hr />
        <div class="row">
          <div class="col-lg-4">
            <div class="card mb-3 shadow">
              <img v-if="applicant.responses[0]" class="card-img-top" :src="getThumbnailUri()" alt="...">
              <div v-else class="bg-light h-100 d-flex flex-column align-items-center justify-content-center min-h-200px">
                <i class="fs-2 bi bi-image d-block"></i>
                <p class="text-muted">No thumbnail</p>
              </div>
              <div class="card-body">
                <h5 class="card-title mb-0">{{ applicant.firstname }} {{ applicant.lastname }}</h5>
                <p class="card-text">{{ applicant.email }}</p>
                <p class="card-text">
                  <span class="badge text-dark" :class="applicantStatus[status(applicant)].class">
                    {{ applicantStatus[status(applicant)].text }}
                  </span>
                  <small v-if="applicant.dateAnswer" class="ms-2 text-muted">{{ DateTime.fromISO(applicant.dateAnswer, {zone: 'utc'}).toLocaleString() }}</small>
                </p>
                <p class="card-text">
                  <small class="text-muted">Expiration date (yyyy-MM-dd) : </small>
                  <vc-date-picker class="inline-block h-full" v-model="deadline" :min-date='new Date()' :attributes="attributes" @dayclick="onDayClick">
                    <template v-slot="{ inputValue, togglePopover }">
                      <small><a @click="togglePopover()" href="#" class="btn-link">{{ DateTime.fromISO(applicant.dateEnd, {zone: 'utc'}).toFormat('yyyy-MM-dd') }}</a></small>
                    </template>
                  </vc-date-picker>
                </p>
                <p class="card-text"><small class="text-muted">Reset interview : <a href="#" @click.prevent="$modal.show('reset-applicant')" class="btn-link">reset</a></small></p>
                <p v-if="applicant.hashKey" class="card-text"><i class="bi bi-phone"></i> <small class="text-muted">{{ applicant.hashKey }}</small></p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-3">
            <code><pre>{{ applicant }}</pre></code>
          </div>
        </div>
      </div>
      <modal name="reset-applicant" height="auto" width="320px" :clickToClose="false">
        <div class="vue-dialog-content">
          <div class="vue-dialog-content-title">Reset applicant</div>
        </div>
        <div class="vue-dialog-buttons d-flex">
          <button type="button" tabindex="0" class="vue-dialog-button w-50" @click="$modal.hide('reset-applicant')">Cancel</button>
          <button type="button" tabindex="0" class="vue-dialog-button btn-primary w-50 text-white" @click="resetApplicant">Reset</button>
        </div>
      </modal>
    </div>

    <Loader v-if="loading" />
    <ErrorCustom v-if="error.error" :text="error.text" />

  </div>
</template>

<script>
import Loader from '../../components/Loader'
import ErrorCustom from '../../components/ErrorCustom'

export default {
  name: 'ApplicantDetails',
  components : {
    Loader,
    ErrorCustom
  },
  data() {
    return {
      loading: false,
      error: {
        error: false,
        text: '',
        code: '',
      },
      applicant: {},
      applicantStatus: [
        { "class": "bg-danger", "text": "Email not view" },
        { "class": "bg-warning", "text": "Email view" },
        { "class": "bg-info", "text": "inprogress" },
        { "class": "bg-success", "text": "completed" },
        { "class": "bg-light", "text": "expired" },
      ],
      deadline: null
    }
  },
  computed: {
    attributes() {
      return [
        {
          key: 'Deadline',
          dot: true,
          color: 'red',
          dates: new Date(this.applicant.dateEnd).toLocaleString('en-EN', { timeZone: 'UTC' }),
        },
      ]
    },
  },
  methods: {
    loadAsyncData(id) {
        this.loading = true
        this.error.error = false
        this.error.text = ''
        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/applicant/' + id
        this.$http.get(endpoint)
          .then(({ data }) => {
              this.applicant = data
              this.loading = false
          })
          .catch((error) => {
              this.error.error = true
              this.error.text = error.response.statusText
              this.loading = false
              throw error
          })
    },
    status(applicant) {
      if (!applicant.dateAnswer && this.DateTime.local() > this.DateTime.fromISO(applicant.dateEnd)) {
        return 4 // expired
      } else {
        if (applicant.questions.length != applicant.responses.length && !applicant.emailView && !applicant.linkClicked) {
          return 0 // email not view
        } else {
          if (applicant.questions.length != applicant.responses.length && !applicant.linkClicked) {
            return 1 // email view
          } else {
            if (applicant.questions.length != applicant.responses.length) {
              return 2 // in progress
            } else {
              return 3 // completed
            }
          }
        }
      }
    },
    getThumbnailUri() {
      return process.env.VUE_APP_THUMBNAIL_URL + '/' + this.applicant.responses[0].thumbnail;
    },
    resetApplicant() {
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/applicant/' + this.applicant._id + '/reset'
      this.$http.put(endpoint)
        .then(({ data }) => {
            this.applicant = data
            this.$toast.success("Reset OK", {})
            this.$modal.hide('reset-applicant')
        })
        .catch((error) => {
            this.$toast.error("Error", {})
            throw error
        })
    },
    onDayClick(day) {
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/applicant/' + this.applicant._id + '/deadline'
      this.$http.put(endpoint, { deadline : day.id + 'T23:59:59.999Z' })
        .then(({ data }) => {
            this.applicant = data
            this.$toast.success("Deadline changed to " + day.id, {})
            this.$modal.hide('reset-applicant')
        })
        .catch((error) => {
            this.$toast.error("Error", {})
            throw error
        })
    },
  },
  created() {
    this.loadAsyncData(this.$route.params.id)
  },
  updated() {
    if (this.applicant._id && this.applicant._id != this.$route.params.id) {
      this.loadAsyncData(this.$route.params.id)
    }
  }
}
</script>
