import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Helpers from './plugins/helpers.js'
import Axios from 'axios'
import { DateTime } from "luxon"
import { ConfigProgrammatic, Table, Skeleton } from 'buefy'
import VModal from 'vue-js-modal'
import VueToast from 'vue-toast-notification'
import VCalendar from 'v-calendar'
import 'vue-toast-notification/dist/theme-sugar.css'
//import 'buefy/dist/buefy.css'
import '@/scss/custom.scss'

Vue.config.productionTip = false
Vue.prototype.DateTime = DateTime
Vue.prototype.$http = Axios;

store.dispatch('tryAutoSignIn')
var accessToken = store.getters.getAccessToken
if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
}


Vue.use(Table)
Vue.use(Skeleton)
Vue.use(VModal, { dialog: true })
Vue.use(VueToast)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
})
//Vue.use(Pagination)
ConfigProgrammatic.setOptions({
    defaultIconPack: 'bi'
})

Vue.use(Helpers)

new Vue({
  router,
  store,
  components: { App },
  render: h => h(App)
}).$mount('#app')
