<template>
  <div class="option">
    <div class="row mb-4">
      <div class="col-lg-6">
        <p class="fs-4 fst-italic">PAYG Pack timeline</p>
          <div class="card">
            <div class="card-body">
              <ul class="timeline">
              <li class="item-add">
                <i class="bi bi-plus"></i>
                <button @click="$modal.show('add-pack')" type="button" class="btn btn-black-button text-white btn-sm">Add a pack</button>
              </li>
              <li v-for="item in timeline" :key="item._id" v-bind:class="[item.title ? 'text-dark' : (item.minute > 0 ? 'text-primary' : 'text-red')]">
                <i v-if="item.title" class="bi bi-arrow-repeat"></i>
                <i v-else-if="item.minute > 0" class="bi bi-bag-plus"></i>
                <i v-else class="bi bi-bag-dash"></i>
                <p class="mb-0">{{ item.title || "Pack " + item.minute + " minutes" }}</p>
                <p class="fw-lighter fst-italic">{{ new Date(item.buyingDate).toLocaleString() }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <modal name="add-pack" height="auto" width="320px" :clickToClose="false">
      <div class="vue-dialog-content">
        <div class="vue-dialog-content-title">Add a Pack</div>
        <form class="form-floating">
          <div class="form-label-group">
            <input v-model="form.minute" type="text" id="inputMinute" v-bind:class="[(form.error ? 'is-invalid' : ''), 'form-control','form-control-sm']" placeholder="Minutes" required="" autofocus="">
            <label for="inputMinute">Minutes</label>
          </div>
        </form>
      </div>
      <div class="vue-dialog-buttons d-flex">
        <button type="button" tabindex="0" class="vue-dialog-button w-50" @click="$modal.hide('add-pack')">Cancel</button>
        <button type="button" tabindex="0" class="vue-dialog-button w-50 btn-black-button text-white" @click="addPack">Valider</button>
      </div>
    </modal>

  </div>
</template>

<script>

export default {
  name: 'CompanyDetailsBilling',
  props: ["initialCompany"],
  data() {
    return {
      company: this.initialCompany,
      timeline: [],
      form: {
        minute: null,
        error: false
      }
    }
  },
  computed: {

  },
  methods: {
    loadAsyncData(id) {
      let elm = this
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + id

      this.$http.get(endpoint + '/pack')
        .then(({ data }) => {
          var items = data
          if (elm.company.subscription.subscriptionType == "PAYG") {
            items.push({_id: "", buyingDate : elm.company.subscription.dateSubscription, title: "Update subscription to PAYG"})
          } else {
            items.push({_id: "", buyingDate : elm.company.subscription.dateSubscription, title: "Update subscription to MONTHLY"})
          }
          const sorted = items.sort((a, b) => new Date(b.buyingDate) - new Date(a.buyingDate))
          elm.timeline = items
        })
        .catch((error) => {

        })
    },
    addPack() {
      let elm = this
      elm.form.error = false
      if (this.form.minute) {
        if (parseInt(this.form.minute)) {
          elm.form.error = false
          const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + this.company._id + '/pack'
          this.$http.post(endpoint, { minutes : this.form.minute})
          .then(({ data }) => {
            elm.form.minute = null
            elm.timeline.unshift(data)
            this.$modal.hide('add-pack')
            this.$toast.success("ok", {})
          })
          .catch((error) => {
            this.$toast.error("Error", {})
          })
        } else {
          elm.form.error = true
        }
      } else {
        elm.form.error = true
      }
    }
  },
  mounted() {
    this.loadAsyncData(this.company._id)
  }
}
</script>
