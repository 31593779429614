<template>
  <div class="card mb-2 shadow border-0">
    <div class="card-body text-center">
      <h5 class="card-title">
        <b-skeleton width="80%" position="is-centered"></b-skeleton>
      </h5>
      <div>
        <h6 class="card-subtitle text-muted fw-light">
          <b-skeleton width="60%" position="is-centered"></b-skeleton>
        </h6>
        <p class="fs-3 text-primary">
          <b-skeleton width="40%" height="30px" position="is-centered"></b-skeleton>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SkeletonCard',
  }
</script>
