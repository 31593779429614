<template>
  <div class="search pt-5">
    <div class="container mb-5">
      <form class="custom-search" v-bind:class="[{ focused : isFocused }]" @submit.prevent="onSubmit()">
        <div class="input-group input-group-lg">
          <input type="text" v-model="keyword" class="form-control bg-transparent" placeholder="Search..."
            @focus="handleFocus()"
            @blur="handleBlur()"
          >
          <button class="btn btn-link text-white" type="button">
            <div class="bi-spin">
              <i class="bi bi-arrow-repeat d-none"></i>
              <i class="bi bi-search" @click.prevent="onSubmit()"></i>
            </div>
          </button>
          <button class="btn btn-link" type="button" @click.prevent="closeSearch()">
            <i class="bi bi-x text-secondary"></i>
          </button>
        </div>
      </form>
    </div>
    <div class="container">
      <div class="row gx-5">
        <div class="col" v-if="!(results.hasResult)">
          No result for "{{ results.noResultText }}"
        </div>
        <div class="col-4" v-if="results.company.length > 0">
          <h4>Company <a href="#" @click.prevent="goToWithQuery('Company')">{{ results.totalCompany }} results</a></h4>
          <ul class="list-group list-group-flush">
            <li v-for="company in results.company" :key="company._id" class="list-group-item">
              <a href="#" @click.prevent="goTo('companyOverview', company._id)">{{ company.name }}</a>
            </li>
          </ul>
        </div>
        <div class="col" v-if="results.applicant.length > 0">
          <h4>Applicant <a href="#" @click.prevent="goToWithQuery('Applicant')">{{ results.totalApplicant }} results</a></h4>
          <ul class="list-group list-group-flush">
            <li v-for="applicant in results.applicant" :key="applicant._id" class="list-group-item">
              <a href="#" @click.prevent="goTo('ApplicantDetails', applicant._id)">{{ applicant.email }} - ({{ applicant.firstname }} {{ applicant.lastname }})</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Search',
    props: ['isSearchOpen'],
    data() {
      return {
        keyword: '',
        isFocused: false,
        isTapping: false,
        loading: false,
        results: {
          company: [],
          totalCompany: 0,
          applicant: [],
          totalApplicant: 0,
          hasResult: true,
          noResultText: ''
        }
      }
    },
    methods: {
      handleFocus() {
        this.isFocused = true
      },
      handleBlur() {
        this.isFocused = false
      },
      closeSearch() {
        this.$emit('update:isSearchOpen', false)
        document.body.classList.remove('search-open');
      },
      goTo(name, params) {
        this.closeSearch()
        this.$router.push({ name: name, params: { id: params }})
      },
      goToWithQuery(name) {
        this.closeSearch()
        this.$router.push({ name: name, query: { search: this.keyword }})
      },
      onSubmit() {
        this.results.hasResult = true
        this.results.noResultText = ''
        if (this.keyword !== '') {
          this.loading = true
          const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION
          const promiseSearchCompany = this.$http.get(endpoint + '/company/search?keyword=' + this.keyword)
          const promiseSearchApplicant = this.$http.get(endpoint + '/applicant/search?keyword=' + this.keyword)
          //const promiseCompletedApplicants = this.$http.get(endpoint + '/applicant?mode=count&status=completed')

          Promise.all([
            promiseSearchCompany.catch(error => { }),
            promiseSearchApplicant.catch(error => { }),
          ]).then(values => {
            this.results.company = values[0].data.docs
            this.results.applicant = values[1].data.docs
            this.results.totalCompany = values[0].data.totalDocs
            this.results.totalApplicant = values[1].data.totalDocs
            this.results.hasResult = !(this.results.totalCompany + this.results.totalApplicant == 0)
            this.results.noResultText = this.keyword
          })
        }
      },
    },
    computed: {
      isAuthenticated: function () {
        return this.$store.state.auth.authenticated
      }
    },
  }
</script>
