<template>
  <div class="informations">
    <div class="row">
      <div class="col-lg-4">
        <form>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="" placeholder="Name">
            <label for="floatingInput" class="">Name</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="" placeholder="Created at">
            <label for="floatingInput" class="">Created at</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="" placeholder="Phone">
            <label for="floatingInput" class="">Phone</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="" placeholder="Website">
            <label for="floatingInput" class="">Website</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="" placeholder="Sub domain">
            <label for="floatingInput" class="">Sub domain</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="" placeholder="Custom domain">
            <label for="floatingInput" class="">Custom domain</label>
          </div>
        </form>
      </div>
      <div class="col-lg-4">
        {{ company.name }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyDetailsInformations',
  props: ["initialCompany"],
  data() {
    return {
      company: this.initialCompany,
    }
  },
  methods: {
    updateData() {
      this.company.name = "yop"
    }
  },
  mounted() {
    console.log("details informations")
  }
}
</script>
