<template>
  <div class="overview container">

    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 mb-5">
      <div class="col">
        <SkeletonCard v-if="loading" />
        <div v-else class="card mb-2 shadow border-0">
          <div class="card-body text-center">
            <h5 class="card-title">PLAN</h5>
            <h6 class="card-subtitle text-muted fw-light">
              {{ subscriptionType(company.subscription.subscriptionType) }}
              <span v-if="company.subscription.subscriptionType == 'MONTHLYWITHOUTCARD'" class="text-decoration-line-through">Card</span>
            </h6>
            <p v-if="company.subscription.subscriptionType == 'PAYG'" class="fs-3 text-primary">{{ minutesUsage.minuteRemaining }}
              <span class="ms-1 text-muted fs-6 d-inline-block">minute(s)</span>
            </p>
            <p class="fs-3 text-primary" v-else>
              <span class="ms-1 text-muted fs-6 d-inline-block">min recorded</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <SkeletonCard v-if="loading" />
        <div v-else class="card mb-2 shadow border-0">
          <div class="card-body text-center">
            <h5 class="card-title">SIMULATOR</h5>
            <div v-if="company.subscription.simulator">
              <h6 class="card-subtitle text-muted fw-light">Enabled</h6>
              <p class="fs-3 text-primary">{{ simulator.total }}</p>
            </div>
            <div v-else>
              <h6 class="card-subtitle text-muted fw-light">Disabled</h6>
              <p class="fs-3 text-muted"><i class="bi bi-x-circle"></i></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <SkeletonCard v-if="loading" />
        <div v-else class="card mb-2 shadow border-0">
          <div class="card-body text-center">
            <h5 class="card-title">INTERVIEW</h5>
            <h6 class="card-subtitle text-muted fw-light">Opened - Closed</h6>
            <p class="fs-3 text-primary">{{ interviews.opened }} - {{ interviews.closed }}</p>
          </div>
        </div>
      </div>
      <div class="col">
        <SkeletonCard v-if="loading" />
        <div v-else class="card mb-2 shadow border-0">
          <div class="card-body text-center">
            <h5 class="card-title">APPLICANT</h5>
            <h6 class="card-subtitle text-muted fw-light">Total - Completed</h6>
            <p class="fs-3 text-primary">{{ applicants.nbTotal }} - {{ applicants.nbCompleted }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" data-masonry='{"percentPosition": true }'>
      <div class="col-lg-6 col-xl-4">
        <SkeletonCardList v-if="loading" />
        <div v-else class="card  mb-2 shadow">
          <div class="card-header fw-bold">
            Informations
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">{{ company.name }}</li>
            <li class="list-group-item"><i class="bi bi-calendar-event me-2"></i>{{ company.createdDate }}</li>
            <li class="list-group-item"><i class="bi bi-telephone me-2"></i>{{ company.phoneNumber }}</li>
            <li class="list-group-item"><i class="bi bi-link-45deg me-2"></i>{{ company.url }}</li>
            <li class="list-group-item">Custom: {{ company.customDomain || "n/a" }}</li>
            <li class="list-group-item">Subdomain: {{ company.subdomain }}</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 col-xl-4">
        <SkeletonCardList v-if="loading" />
        <div v-else class="card mb-2 shadow">
          <div class="card-header fw-bold">
            Options
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><i class="bi bi-person-lines-fill me-2"></i> {{ company.subscription.teamSize }}  <span class="badge rounded-pill bg-dark float-end">Team size</span></li>
            <li class="list-group-item">
              <span>
                <i
                  v-bind:class="[company.subscription.simulator ? 'bi-check-square' : 'bi-square', iconPack, hoverClass]"
                  @mouseover="hoverClass = 'text-primary'"
                  @mouseout="hoverClass = ''"
                  @click="toggleOption('simulator')"
                ></i> {{ hasOptions(company.subscription.simulator) }}
              </span>
              <span class="badge rounded-pill bg-dark float-end">Simulator</span>
            </li>
            <li class="list-group-item"><i class="bi bi-link me-2"></i> {{ hasOptions(company.subscription.publicLink) }}  <span class="badge rounded-pill bg-dark float-end">Public link</span></li>
            <li class="list-group-item"><i class="bi bi-bell me-2"></i> {{ company.subscription.minuteAlert || "20" }}  <span class="badge rounded-pill bg-dark float-end">Minutes alert</span></li>
            <li v-if="company.subscription.contact_id" class="list-group-item"> {{ company.subscription.contact_id }} <span class="badge rounded-pill bg-dark float-end">Zoho</span></li>
            <li v-if="company.customerId" class="list-group-item"> {{ company.customerId }} <span class="badge rounded-pill bg-dark float-end">Stripe</span></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 col-xl-4">
        <SkeletonCardList v-if="loading" />
        <div v-else class="card mb-2 shadow">
          <div class="card-header fw-bold">
            Teams
          </div>
          <ul class="list-group list-group-flush">
            <li v-for="user in users" :key="user._id" class="list-group-item">
              <i v-if="(user.role == 'OWNER')" class="bi bi-person-square me-2"></i> {{ user.firstname }} {{ user.lastname }} - {{ user.email }}
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Loader from '../../components/Loader'
import SkeletonCard from '../../components/skeleton/Card'
import SkeletonCardList from '../../components/skeleton/CardList'

export default {
  name: 'CompanyDetailsOverview',
  props: ["initialCompany"],
  components : {
    SkeletonCard,
    SkeletonCardList
  },
  data() {
    return {
      company: this.initialCompany,
      applicants: {
        nbTotal: 0,
        nbCompleted: 0,
      },
      interviews: {
        opened: 0,
        closed: 0,
      },
      simulator: {
        total: 0
      },
      users: [],
      minutesUsage: {},
      hoverClass: '',
      iconPack: 'bi',
      loading: true,
    }
  },
  computed: {

  },
  methods: {
    loadAsyncData(id) {
        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + id
        const promiseTotalApplicants = this.$http.get(endpoint + '/applicant?mode=count')
        const promiseCompletedApplicants = this.$http.get(endpoint + '/applicant?mode=count&status=completed')
        const promiseOpenedInterview = this.$http.get(endpoint + '/interview?mode=count')
        const promiseClosedInterview = this.$http.get(endpoint + '/interview?mode=count&status=closed')
        const promiseSimulator = this.$http.get(endpoint + '/simulator?mode=count')
        const promiseMinutesUsage = this.$http.get(endpoint + '/subscription/usage')
        const promiseUsers = this.$http.get(endpoint + '/account')

        Promise.all([
          promiseTotalApplicants.catch(error => { }),
          promiseCompletedApplicants.catch(error => { }),
          promiseOpenedInterview.catch(error => { }),
          promiseClosedInterview.catch(error => { }),
          promiseSimulator.catch(error => { }),
          promiseMinutesUsage.catch(error => { }),
          promiseUsers.catch(error => { }),
        ]).then(values => {
          this.loading = false
          this.applicants.nbTotal = values[0].data
          this.applicants.nbCompleted = values[1].data
          this.interviews.opened = values[2].data
          this.interviews.closed = values[3].data
          this.simulator.total = values[4].data
          this.minutesUsage = values[5].data
          this.users = values[6].data
        })
    },
    subscriptionType(value) {
        if (value === "PAYG") {
            return 'PAYG'
        } else if (value == "MONTHLY") {
            return 'MONTHLY'
        } else if (value == "MONTHLYWITHOUTCARD") {
            return 'MONTHLY'
        }
    },
    hasOptions(option) {
      return (option) ? 'Enabled' : 'Disabled'
    },
    toggleOption(option) {
      var elm = this
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + this.company._id + '/options'
      this.$http.put(endpoint, { simulator : !elm.company.subscription.simulator})
      .then(({ data }) => {
        this.company.subscription.simulator = data.subscription.simulator
        this.$toast.success(data.subscription.simulator ? "Simulator enabled" : "Simulator disabled", {})
      })
      .catch((error) => {
        this.$toast.error("Error", {})
      })
    },
  },
  mounted() {
    this.loadAsyncData(this.company._id)
  }
}
</script>
