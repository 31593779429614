<template>
  <div class="container">
    <form class="form-auth" @submit.prevent="onSubmit()">
      <div class="text-center mb-4">
        <h4>Re-send Confirmation Code</h4>
      </div>

      <div class="alert alert-danger" role="alert" v-show="showerr">
        {{ errmsg }}
      </div>

      <div class="form-label-group">
        <input v-model="email" type="text" id="inputEmail" class="form-control" placeholder="Email address" autofocus="">
        <label for="inputEmail">Email address</label>
      </div>

      <button class="btn btn-primary btn-block d-block" type="submit" :disabled="sending">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="sending"></span>
        Resend
      </button>
      <div class="d-flex justify-content-between mt-2">
        <router-link :to="{ name: 'Login' }">Sign in</router-link>
      </div>
    </form>
  </div>
</template>

<script>

  var AmazonCognitoIdentity = require('amazon-cognito-identity-js')

  export default {
    data: () => ({
      userPool: [],
      congitoUser: {},
      confirmed: false,
      showerr: false,
      errcode: '',
      errmsg: '',
      email: '',
      valid: false,
      loader: false,
      sending: false,
    }),
    methods: {
      onSubmit() {
        this.loader = 'sending'
        const l = this.loader
        this[l] = !this[l]
        this.errcode = ''
        this.errmsg = ''
        this.showerr = false

        var userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId : process.env.VUE_APP_AWS_COGNITO_USER_POOL,
          ClientId: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID
        })
        var userData = {
          Username: this.email,
          Pool: userPool
        }
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
        cognitoUser.resendConfirmationCode((err, result) => {
          if (err) {
            this.errcode = JSON.stringify(err.code)
            this.loader = false
            this.sending = false
          } else {
            this.$store.commit('setUsername', this.email)
            this.$router.push('/confirm')
          }
        })
      },
      getMessage: function () {
        return this.message
      }
    },
    watch: {
      errcode () {
        this.loader = 'sending'
        const l = this.loader
        this[l] = !this[l]
        console.log('watched error code: ' + this.errcode)
        if (this.errcode !== '') {
          if (this.errcode === '"CodeMismatchException"') {
            this.errmsg = 'Invalid verification code provided'
          } else if (this.errcode === '"NotAuthorizedException"') {
            this.errmsg = 'The user has already been confirmed'
          } else if (this.errcode === '"UserNotFoundException"') {
            this.errmsg = 'Username id not found!'
          } else if (this.errcode === '"LimitExceededException"') {
            this.errmsg = 'Attempt limit exceeded, please try after some time'
          } else {
            this.errmsg = 'An error has occured!'
          }
          this.showerr = true
        }
        this[l] = false
        this.loader = null
      }
    },
    created () {
      this.email = this.$store.state.auth.username || ''
    }
  }
</script>
