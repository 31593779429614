<template>
  <div class="teams">
    <div class="row mb-4">
      <div class="col">
        <p class="fs-4 fst-italic">Manage users <small>({{ accounts.length }})</small></p>
        <ul class="list-group list-group-flush">
          <li v-for="account in accounts" :key="account._id" class="list-group-item">
            <i class="bi bi-person-badge" v-if="account.email == 'support@itwapp.io'"></i> {{ account.firstname }} {{ account.lastname }} - {{ account.email }} <span class="badge bg-dark text-white">{{ account.role }}</span>
            <span v-if="account.email == 'support@itwapp.io'" class="badge btn-black-button ms-2" @click="removeAccount(account.email)">Remove</span>
          </li>
          <li class="list-group-item" v-show="!hasSupportAccount">
            <a @click.prevent="addSupport()" href="#" class="link-primary"><i class="bi bi-plus"></i> ajouter support@itwapp.io</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p class="fs-4 fst-italic">Manage invitations <small>({{ invitations.length }})</small></p>
        <ul class="list-group list-group-flush">
          <li v-for="invitation in invitations" :key="invitation._id" class="list-group-item">
            {{ invitation.email }} <span class="badge bg-dark text-white">{{ invitation.role }}</span>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CompanyDetailsTeam',
  props: ["initialCompany"],
  data() {
    return {
      company: this.initialCompany,
      accounts: [],
      invitations: [],
    }
  },
  computed: {
    hasSupportAccount() {
      return this.accounts.find(x => x.email == 'support@itwapp.io')
    }
  },
  methods: {
    loadAsyncData(id) {
        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + id
        const promiseAccounts = this.$http.get(endpoint + '/account')
        const promiseInvitations = this.$http.get(endpoint + '/account/invitation')

        Promise.all([
          promiseAccounts.catch(error => { }),
          promiseInvitations.catch(error => { }),
        ]).then(values => {
          this.accounts = values[0].data
          this.invitations = values[1].data
        })
    },
    addSupport() {
      let elm = this
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + this.company._id + '/account'
      this.$http.post(endpoint, { email : 'support@itwapp.io'})
      .then(({ data }) => {
        elm.accounts.push(data)
        this.$toast.success("ok", {})
      })
      .catch((error) => {
        var message = error.response.data.reason || "Error"
        this.$toast.error(message, {})
      })
    },
    removeAccount(email) {
      let elm = this
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/company/' + this.company._id + '/account'
      this.$http.put(endpoint, { email : email})
      .then(({ data }) => {
        elm.accounts = elm.accounts.filter( x => x.email != email)
        this.$toast.success("ok", {})
      })
      .catch((error) => {
        this.$toast.error("Error", {})
      })
      //this.accounts = this.accounts.filter( x => x._id != id)
    },
    updateData() {
      this.company.name = "yop"
    }
  },
  mounted() {
    this.loadAsyncData(this.company._id)
  }
}
</script>
