<template>
  <div id="app" v-bind:class="[{ showsidebar: showSidebar }]">
    <div class="toggle" v-if="isAuthenticated">
      <a href="#" class="burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar" @click.prevent="showSidebar = !showSidebar">
        <span></span>
      </a>
    </div>
    <TheNavbar/>
    <TheSidebar/>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<style>

</style>

<script>

  import TheNavbar from './components/TheNavbar'
  import TheSidebar from './components/TheSidebar'

  export default {
    components : {
      TheNavbar,
      TheSidebar,
    },
    data() {
      return {
        showSidebar: false,
        openSearch: false,
      };
    },
    computed: {
      isAuthenticated: function () {
        return this.$store.state.auth.authenticated
      }
    },
    watch:{
      $route (to, from){
        this.showSidebar = false
      }
    },
    mounted() {
      //let bs5 = document.createElement('script')
      //bs5.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha1/js/bootstrap.bundle.min.js')
      //document.head.appendChild(bs5)
      let masonry = document.createElement('script')
      masonry.setAttribute('src', 'https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js')
      masonry.setAttribute('integrity', 'sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D')
      masonry.setAttribute('crossorigin', 'anonymous')
      masonry.setAttribute('async', 'async')
      document.head.appendChild(masonry)

    },
    created : function() {

    },
  }
</script>
