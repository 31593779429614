<template>
  <div class="provider">
    <div class="container">
      <h4>Provider / {{ provider.name }}</h4>
      <hr />
      <div class="row">
        <div class="col-md-8">
          <form>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="baseuri" placeholder="Base uri" :value="provider.baseUri">
              <label for="baseuri">BaseUri</label>
            </div>
            <div class="form-floating mb-3">
              <input disabled type="text" class="form-control" id="clientid" placeholder="client id" :value="provider.clientId">
              <label for="clientid">Client Id</label>
            </div>
            <div class="form-floating mb-3">
              <input disabled type="text" class="form-control" id="clientsecret" placeholder="client secret" :value="provider.clientSecret">
              <label for="clientsecret">Client Secret</label>
            </div>
            <div class="form-floating mb-3">
              <textarea disabled type="text" class="form-control" id="accesstoken" placeholder="client secret" :value="provider.accessToken" style="height: 150px;"></textarea>
              <label for="accesstoken">Access Token</label>
            </div>
            <div v-if="provider.refreshToken" class="form-floating mb-3">
              <input disabled type="text" class="form-control" id="refreshtoken" placeholder="client secret" :value="provider.refreshToken">
              <label for="accesstoken">Refresh Token</label>
            </div>
          </form>
        </div>
        <div class="col-md-4">
          <div class="fs-5 mb-3">
            <i v-if="!tokenExpired(provider.expireAt)" class="bi bi-check2-circle text-primary"></i>
            <i v-else class="bi bi-x-circle text-danger"></i>
            Expired at <span id="tooltipExp" class="badge bg-dark">{{ DateTime.fromMillis(provider.expireAt * 1000).toUTC().toISO() }}</span>
          </div>
          <div class="d-grid gap-2">
            <button type="button" :disabled="!tokenExpired(provider.expireAt)" class="btn btn-primary btn-block" @click.prevent="yobsAuth(provider._id)">Auth to {{ provider.name }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyDetails',
  data() {
    return {
      loading: true,
      provider: {},
    }
  },
  methods: {
    loadAsyncData(id) {
        const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/provider/' + id
        this.$http.get(endpoint)
            .then(({ data }) => {
                this.provider = data
                this.loading = false
            })
            .catch((error) => {
                this.loading = false
                throw error
            })
    },
    yobsAuth(id) {
      var elm = this
      const endpoint = process.env.VUE_APP_BASE_API + process.env.VUE_APP_BASE_API_VERSION + '/provider/' + id + '/auth'
      this.$http.post(endpoint, {}, {
         headers: {
              'Content-Type': 'application/json'
         }
      })
      .then(({ data }) => {
        elm.provider.accessToken = data.token
        elm.provider.expireAt = data.expireAt
        this.$toast.success(data.message, {})
      })
      .catch((error) => {
        this.$toast.error(error.response.data.message, {})
      })
    },
    tokenExpired(expireAt) {
      return (Math.floor(Date.now() / 1000) >= expireAt)
    }
  },
  created() {
      this.loadAsyncData(this.$route.params.id)
  }
}
</script>
